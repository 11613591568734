<template>
  <b-row
      class="d-flex flex-row align-items-start justify-content-start"
      no-gutters
  >
    <b-row
        class="d-flex flex-row align-items-start justify-content-start px-3 py-1 w-100 table-container-row"
        no-gutters
    >
      <b-table
          ref="selectableTable"
          :bordered="false"
          :borderless="true"
          :hover="true"
          show-empty
          select-mode="multi"
          class="table-element"
          thead-class="table-header font-secondary"
          tbody-class="table-body"
          :busy="isLoading"
          :fields="transactionsHeader"
          :items="transactionsItems"
      >
        <!-- table busy state -->
        <template #table-busy>
          <div class="text-center text-muted my-3">
            <b-spinner class="align-middle"></b-spinner>
            <span class="text-left mx-1">Loading...</span>
          </div>
        </template>
        <!-- table empty state -->
        <template #empty="scope">
          <b-row
              class="d-flex flex-row align-items-center justfiy-content-center py-5"
              no-gutters
          >
            <h6 class="text-center mb-0 mx-auto my-5">
              {{ scope.emptyText }}
            </h6>
          </b-row>
        </template>
        <!-- transaction Date  -->
        <template #cell(transactionDate)="data">
          <b-col
              class="d-flex flex-column align-items-start justify-content-center px-0"
          >
            <span class="main-text">{{ data.value }}</span>
          </b-col>
        </template>
        <!-- transaction Description -->
        <template #cell(transactionDescription)="data">
          <span class="main-text mb-2">{{ data.value }}</span>
        </template>
        <!-- transaction Amount  -->
        <template #cell(transactionAmount)="data">
          <span class="main-text mb-2">{{ `${currency} ${data.value}` }}</span>
        </template>
        <!-- transaction Type -->
        <template #cell(transactionType)="data">
          <span class="main-text mb-2">{{ data.value }}</span>
        </template>
        <!-- transaction Particulars -->
        <template #cell(transactionParticulars)="data">
          <span class="main-text mb-2">{{ data.value }}</span>
        </template>
        <!-- transaction Code -->
        <template #cell(transactionCode)="data">
          <span class="main-text mb-2">{{ data.value }}</span>
        </template>
        <!-- transaction Reference -->
        <template #cell(transactionReference)="data">
          <span class="main-text mb-2">{{ data.value }}</span>
        </template>
      </b-table>
    </b-row>
    <!-- select and button row  -->
    <b-row
        class="d-flex flex-row align-items-start justify-content-start w-100 px-3 mt-4"
        no-gutters
    >
      <b-col
          class="d-flex flex-column align-items-start justify-content-center"
          cols="12"
          md="6"
      >
        <b-row
            class="d-flex flex-row align-items-start justify-content-start w-100"
        >
          <b-col cols="6" md="5">
            <span class="text-left text-dark font-primary font-weight-bold"
            >Select Amounts Column</span
            >
          </b-col>
          <b-col cols="6" md="7">
            <FormSelect
                groupId="fiscalyear-group"
                id="fiscalyear-group-input"
                class="text-prime-gray rounded-2"
                v-model="selectAmount"
                :whiteBG="false"
                :options="columnOptions"
                form="login-form"
            ></FormSelect>
          </b-col>
          <span
              v-if="isAmountInvalid"
              class="block text-center text-sm text-danger font-body mt-1 mb-3"
          >
          Please enter a valid amount. The amount should be a numeric value and can be positive or negative. Remove any symbols, currency signs, or non-numeric characters. For example, valid amounts include "123.45," "-42," or "0.32."
        </span>
        </b-row>
      </b-col>
      <b-col
          class="d-flex flex-column align-items-center align-items-md-end justify-content-center"
          cols="12"
          md="6"
      >
        <b-button
            variant="main-green"
            class="d-flex flex-row align-items-center justify-content-center mr-2 my-2 my-lg-0 px-4 py-2 border-0rounded-lg add-question-btn"
            pill
            @click="nextFn()"
            :disabled="!isEnable"
        >
          <span class="d-inline mx-1 py-1">Confirm amounts</span>
        </b-button>
      </b-col>
    </b-row>
  </b-row>
</template>
<script>
// services
// import { GetAllTransactions } from "@/services/transactions.service";
import {mapGetters} from "vuex";
import FormSelect from "@/components/Form/FormSelect";
import {GetCurrentWorkspace} from "@/services/settingsbusiness.service";

export default {
  name: "SelectAmounts",
  components: {
    FormSelect,
  },
  data() {
    return {
      isLoading: false,
      isEnable: false,
      selectAmount: null,
      columnOptions: [],
      transactionsHeader: [
        {
          key: "transactionDate",
          label: "Date",
          sortable: true,
        },
        {
          key: "transactionDescription",
          label: "Description",
        },
        {
          key: "transactionAmount",
          label: "Amount",
          class: "text-right",
        },
        {
          key: "transactionCategory",
          label: "Cr/Dr",
          class: "text-right",
        },
        {
          key: "transactionType",
          label: "Type",
        },
        {
          key: "transactionParticulars",
          label: "Particulars",
        },
        {
          key: "transactionCode",
          label: "Code",
        },
        {
          key: "transactionReference",
          label: "Reference",
        },
      ],
      transactionsItems: [],
      currency: localStorage.getItem('CURRENCY'),
      isAmountInvalid: false
    };
  },
  watch: {
    tabState(val) {
      console.log("tabstate", val);
    },
    selectAmount(val) {
      const amountRegex = /^-?\d+(\.\d{1,2})?/;
      this.isAmountInvalid = false;
      const canProceed = this.bankStatementItems.slice(1).every((item) => {
        return amountRegex.test(item[val]);
      });
      this.isEnable = val >= 0 && canProceed;
      this.isAmountInvalid = !canProceed;
    },
  },
  computed: {
    ...mapGetters({
      getTabState: "general/getTabState",
      getBankStatementItems: "transactions/getBankStatementItems",
    }),
    tabState: {
      get() {
        return this.getTabState;
      },
      set(val) {
        this.$store.dispatch("general/setTabState", val);
      },
    },
    bankStatementItems: {
      get() {
        return this.getBankStatementItems;
      },
    },
  },
  async mounted() {
    // await this.loadCurrentWorkspace();
    this.initFn();
  },
  methods: {
    async loadCurrentWorkspace() {
      let {data} = await GetCurrentWorkspace();
      this.currency = data.currency;
    },
    nextFn() {
      this.tabState++;
      this.$store.dispatch("transactions/setAmountCol", this.selectAmount);
    },
    initFn() {
      this.columnOptions = this.getBankStatementItems[0].map((x, index) => {
        return {
          text: x,
          value: index,
        };
      });
      this.transactionsHeader = this.getBankStatementItems[0].map((x) => {
        return {
          key: x,
          label: x,
        };
      });
      for (let i = 1; i < this.getBankStatementItems.length; i++) {
        const row = this.getBankStatementItems[i];
        const item = {};
        for (let j = 0; j < row.length; j++) {
          const element = row[j];
          const key = this.getBankStatementItems[0][j];
          item[key] = element;
        }
        this.transactionsItems.push(item);
      }
    },
  },
};
</script>

<style scoped>
.add-question-btn:hover {
  background: #0B9B7D;
  box-shadow: inset 0px 0px 10px #ffffff;
  border: none;
}

/* table container */
.table-container-row {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}

/* scrollbar size fix for webkit browsers (chrome/safari) */
.table-container-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}

.table-container-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}

.table-container-row::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}

.table-container-row .table-element {
  min-width: 1000px;
}

/* table element css */
.table-element >>> .table-header {
  /* background-color: #f7f7ff; */
  background-color: transparent;
}

.table-element >>> .table-header th {
  color: var(--prime-gray);
  font-size: 0.8rem;
  font-weight: 400;
  border-color: transparent;
}

.table-element >>> .table-body td {
  vertical-align: middle;
}

.table-element >>> .table-body tr:nth-child(even) {
  vertical-align: middle;
  background-color: var(--light);
}

.table-element >>> .table-body .main-text {
  font-size: 0.9rem;
}

.table-element >>> .table-body .sub-text {
  font-size: 0.7rem;
}

/* hyperlink custom styling */
.table-element >>> .table-body td {
  vertical-align: middle;
  padding: 0;
}

.table-element >>> .table-body td > a {
  padding: 0.75rem;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .table-card-row .filter-dropdown,
  .table-card-row .export-dropdown {
    min-width: auto;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .insight-cards-row {
    height: auto;
  }
}
</style>
