<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-start justify-content-start px-0 root-container"
    >
      <b-row
        class="d-flex flex-row align-items-center justify-content-start px-3 my-2 ml-3 w-100 card-header-row"
        no-gutters
      >
       <b-col cols="8">
        <h4 class="py-3">Upload a bank statement</h4>
       </b-col>
       <b-col cols="4">
        <b-link
           @click="downloadStatement"
           class="d-flex flex-row justify-content-center text-main-green font-weight-bold "
        >
        Download Figa’s Bank Statement Format
        </b-link>
       </b-col>
      </b-row>
      <!-- invoice summary row -->
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center w-100 px-3 main-card-row"
        no-gutters
      >
        <b-row
          class="d-flex flex-row align-items-center justify-content-start px-3 w-100 card-header-row"
          no-gutters
        >
          <h5 class="py-3">1. Download your electronic statement</h5>
        </b-row>
        <b-card
          no-body
          class="position-relative overflow-hidden shadow-sm border-1 border-gray-550 insight-cards-row w-100"
        >
          <b-row
            class="d-flex flex-row align-items-center justify-content-start px-3 w-100 card-header-row"
            no-gutters
          >
            <h6 class="py-3">
              Visit your online banking website and download an electronic
              statement for your bank or credit card account in any of these
              file types:
            </h6>
          </b-row>
          <b-row
            class="d-flex flex-row align-items-center justify-content-start px-3 w-100 card-header-row"
            no-gutters
          >
            <span class="pb-4 text-dark-blue mr-3"> .Csv </span>
            <!-- <span class="pb-4 text-primary ml-3">
              .Xlsx
            </span> -->
          </b-row>
          <b-row
            class="d-flex flex-row align-items-center justify-content-start px-3 w-100 card-header-row"
            no-gutters
          >
            <span class="pb-4 text-gray-550">
              File must contain an amount, date and description columns.
            </span>
          </b-row>
        </b-card>
      </b-row>
      <!-- Notes / Terms row -->
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center w-100 px-3 main-card-row"
        no-gutters
      >
        <b-row
          class="d-flex flex-row align-items-center justify-content-start px-3 w-100 card-header-row"
          no-gutters
        >
          <h5 class="pb-3">2. Upload your statement</h5>
        </b-row>
        <b-card
          no-body
          class="position-relative overflow-hidden shadow-sm border-1 border-gray-550 notes-cards-row w-100"
        >
          <b-row
            class="d-flex flex-row align-items-center justify-content-start px-3 w-100 card-header-row"
            no-gutters
          >
            <h6 class="py-3">Upload your file here. *</h6>
          </b-row>
          <b-row
            class="d-flex flex-row align-items-center justify-content-start my-md-2 px-3 w-100"
            no-gutters
          >
            <b-col cols="12" lg="6">
              <b-form-file
                ref="fileUpload"
                v-model="bankStatementFile"
                placeholder="Browse or drop your file here."
                drop-placeholder="Drop file here..."
                accept=".csv, .xls"
              ></b-form-file>
            </b-col>
          </b-row>
          <b-row
            class="d-flex flex-row align-items-center justify-content-start px-3 w-100 card-header-row"
            no-gutters
          >
            <h6 class="py-3">Payment account *</h6>
          </b-row>
          <b-row
            class="d-flex flex-row align-items-center justify-content-start mt-md-2 px-3 w-100"
            no-gutters
          >
            <b-col cols="12" lg="6">
              <FormSelect
                groupId="fiscalyear-group"
                id="fiscalyear-group-input"
                class="text-prime-gray rounded-2"
                label="Account"
                v-model="accountType"
                :whiteBG="false"
                :options="accountTypeOptions"
                form="login-form"
              ></FormSelect>
            </b-col>
            <!-- <b-col cols="1">
              <b-button
                variant="transparent"
                class="d-flex flex-row align-items-center justify-content-center mx-0 my-2 my-lg-0 border-none rounded-lg add-question-btn"
                pill
                @click="openAddAccountModal($event.target)"
              >
                <b-icon
                  class="text-secondary mr-2"
                  icon="plus-circle-fill"
                  size="sm"
                  aria-label="Plus"
                  font-scale="1.1"
                ></b-icon>
              </b-button>
            </b-col> -->
          </b-row>
          <b-row
            class="d-flex flex-row align-items-center justify-content-start px-3 w-100 card-header-row"
            no-gutters
          >
            <span class="pb-4 text-gray-550">
              Select the account for which you're uploading a statement, or add
              a new account.
            </span>
          </b-row>
          <b-row
            class="d-flex flex-row align-items-center justify-content-start mb-4 px-3 w-50"
            no-gutters
          >
            <b-button
              id="upload-btn"
              variant="main-green"
              class="d-flex flex-row align-items-center justify-content-center mr-2 my-2 my-lg-0 px-4 py-2 border-0 rounded-lg add-question-btn"
              pill
              :disabled="!accountType || !bankStatementFile"
              @click="uploadBankStatement()"
            >
              <span class="d-inline mx-1 pb-1">Upload</span>
            </b-button>
            <b-tooltip
              id="proceed-upload-helper-tooltip"
              target="upload-btn"
              triggers="manual"
              placement="top"
              boundary="window"
              offset="0"
            >
              {{ uploadButtonText }}
            </b-tooltip>
          </b-row>
          <span
              v-if="uploadError.status"
              class="block text-center text-sm text-danger font-body mt-1 mb-3"
          >
          {{ uploadError.message }}
        </span>
          <span
              v-if="uploadError.status"
              class="block text-center text-sm text-danger font-body mt-1 mb-3"
          >
          {{ uploadError.format }}
        </span>
        </b-card>
      </b-row>
      <!-- Footer row -->
      <b-row
        class="d-flex flex-row align-items-center justify-content-center w-100 px-3 main-card-row"
        no-gutters
      >
        <b-row
          class="d-flex flex-row align-items-center justify-content-start px-3 w-100 card-header-row"
          no-gutters
        >
          <h5 class="pb-3">3. Manage your transactions</h5>
        </b-row>
        <b-card
          no-body
          class="position-relative overflow-hidden shadow-sm border-1 border-gray-550 footer-cards-row w-100"
        >
          <b-row
            class="d-flex flex-row align-items-center justify-content-start px-3 w-100 card-header-row"
            no-gutters
          >
            <h6 class="pt-3">
              After you upload the file, Transactions in the file will be added
              to the Imported Transactions page.
            </h6>
          </b-row>
          <b-row
            class="d-flex flex-row align-items-center justify-content-start my-3 my-md-2 my-lg-3 px-3 w-100"
            no-gutters
          >
          </b-row>
        </b-card>
      </b-row>
    </b-container>
  </HomeLayout>
</template>
<script>
// @ is an alias to /src
import HomeLayout from "@/layout/HomeLayout";
// import FormInput from "@/components/Form/FormInput";
import FormSelect from "@/components/Form/FormSelect";

// services
import {
  GetCashAndBank,
  UploadBankStatement,
} from "@/services/transactions.service";

export default {
  name: "UploadBankStatement",
  components: {
    HomeLayout,
    // FormInput,
    FormSelect,
  },
  data() {
    return {
      uploadButtonText: "",
      accountType: null,
      bankStatementFile: null,
      accountTypeOptions: [],
      statementItems: [],
      csvBase64: null,
      uploadError:{
        status:false,
        message:'',
        format:''
      }
    };
  },
  async mounted() {
    await this.initFn();
  },
  computed: {
    computedIsEnableUpload() {
      if (this.bankStatementFile && this.accountType) {
        return true;
      }
      return false;
    },
  },
  watch: {
    computedIsEnableUpload(val) {
      if (val) {
        // show popover
        // trigger popover
        this.uploadButtonText = "Click here to match the columns";
        // only show popover for three seconds
        this.$root.$emit("bv::show::tooltip", "proceed-upload-helper-tooltip");
        // hide after 3 seconds
        setTimeout(() => {
          this.$root.$emit(
            "bv::hide::tooltip",
            "proceed-upload-helper-tooltip"
          );
          this.uploadButtonText = null;
        }, 3000);
      } else {
        this.$root.$emit("bv::hide::tooltip", "proceed-upload-helper-tooltip");
        this.uploadButtonText = null;
      }
    },
  },
  methods: {
    getBase64(file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        //   this.isApiLoad = true;
        this.csvBase64 = reader.result;
        console.log("base", this.csvBase64);
        let data = UploadBankStatement(this.csvBase64);
        console.log("bank", data);
      };
      this.$router.push("/transactions/upload_bank_statement/csv_import");
    },
    async initFn() {
      // get cash and bank
      try {
        let { data } = await GetCashAndBank();
        console.log("GetCashAndBank", data.data);
        this.accounts = data.data;
        console.log("this account", this.accounts);
        let accountsArray = [];
        this.accounts.forEach((account) => {
          accountsArray.push({
            value: {
              type: account.group,
              subcategory: account.subgroup,
              account: account.account,
            },
            text: account.account,
          });
        });
        this.accountTypeOptions = [
          {
            label: this.accounts[0].subgroup,
            options: accountsArray,
          },
        ];
      } catch (error) {
        console.log(error);
      }
    },
    async uploadBankStatement() {
      this.uploadError.status = false;
      this.uploadError.message = '';
      this.uploadError.format = '';
      try {
        // this.getBase64(this.bankStatementFile);
        // let form_data = new FormData();
        // form_data.append("file", this.bankStatementFile);
        // console.log("form_data", form_data);
        // let data = await UploadBankStatement(form_data);
        // console.log("bank", data);
        const file = this.$refs.fileUpload.files[0];
        let formData = new FormData();
        formData.append("file", file);
        console.log("form data", formData);
        const result = await UploadBankStatement(formData);
        if (result.status === 200 && result.data.date_format) {
          console.log("result", result);
          this.statementItems = result.data;
          this.$router.push("/transactions/upload_bank_statement/csv_import");
          console.log("statementItems", this.statementItems);
          this.$store.dispatch("transactions/setAll", result.data);
          this.$store.dispatch("transactions/setAccount", this.accountType);
        } else {
          this.uploadError.status = true;
          this.uploadError.message = "We are having a difficulty reading the dates of your transactions.";
          this.uploadError.format = "Please make sure the file is in correct format. ex: DD/MM/YYYY, YYYY/MM/DD separated by slash(/), dash(-), or comma (,)";
          let payloadNotify = {
            isToast: true,
            title: "Oops! Something isn't right.",
            content:
              "We are having a difficulty reading the dates of your transactions. Please make sure the file is in correct format. ex: DD/MM/YYYY, YYYY/MM/DD separated by slash(/), dash(-), or comma (,)",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
          console.log("notify", payloadNotify);
        }
      } catch (error) {
        // let payloadNotify = {
        //   isToast: true,
        //   title: "Oops! Something isn't right.",
        //   content:
        //     "We are having a difficulty reading the dates of your transactions. Please make sure the file is in correct format",
        //   variant: "danger",
        // };
        // this.$store.dispatch("notification/setNotify", payloadNotify);
        // console.log("notify", payloadNotify);
        console.log(error);
      }
    },
    downloadStatement() {
      // Replace the URL with the actual URL of the statement file
      const url = 'https://figadev.s3.amazonaws.com/Figa+Bank+Statement+Template.csv'

      // Create a new anchor element to trigger the download
      const link = document.createElement('a')
      link.href = url
      link.download = 'Figa Bank Statement Template.csv'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
  },
};
</script>

<style scoped>
.root-container {
  min-height: 600px;
  width: 100%;
  background-color: white;
  border-radius: 5px;
}
.root-container .main-card-row {
}
/* insights card */
.insight-cards-row {
  min-height: 150px;
  width: 100%;
  background-color: var(--white);
  border-radius: 5px;
  border: 1px solid var(--dark);
  margin-bottom: 30px;
}
/* .insight-cards-row .add-customer-btn {
  margin-top: 50px;
} */
.insight-cards-row .customer-details-col {
  font-size: 0.9rem;
}
.insight-cards-row .filter-dropdown {
  height: 200px;
  min-width: 300px;
}
.insight-cards-row .filter-dropdown >>> .add-customer-btn {
  /* height: 50px; */
  padding-left: 100px;
}
.insight-cards-row .filter-dropdown >>> .filter-dropdown-menu {
  height: auto;
  min-width: 200px;
  /* min-height: 12vh; */
  /* overflow-x: hidden; */
  overflow-y: auto;
  scrollbar-width: thin;
}
.insight-cards-row .date-picker >>> input.form-control:focus {
  height: auto;
  box-shadow: none;
  border-color: #0B9B7D;
}
.insight-cards-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item.active,
.insight-cards-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item:active {
  background-color: var(--light);
}
.items-cards-row {
  height: auto;
  width: 100%;
  background-color: var(--white);
  border-radius: 5px;
  border: 1px solid var(--dark);
  margin-bottom: 30px;
  overflow: visible;
  position: static;
}
.footer-cards-row {
  min-height: 70px;
  width: 100%;
  padding-top: 14px;
  background-color: var(--white);
  border-radius: 5px;
  border: 1px solid var(--dark);
  margin-bottom: 30px;
}
.notes-cards-row {
  min-height: 250px;
  width: 100%;
  background-color: var(--white);
  border-radius: 5px;
  border: 1px solid var(--dark);
  margin-bottom: 30px;
}
.add-question-btn{
  height: 40px;
  padding-top: 20px;
}

.add-question-btn:hover {
  background: #0B9B7D;
  box-shadow: inset 0px 0px 10px #ffffff;
}
/* .insight-cards-row .card-header-row {
  border: 5px;
} */
/* table card */
.table-card-row {
  height: 100%;
}
.table-card-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  min-height: 560px;
  border-radius: 5px;
  border: 1px solid var(--light);
}
.table-card-row .search-input-group {
  box-shadow: none;
  height: 50px;
  max-width: 350px;
}
.table-card-row .search-input-group .search-input {
  box-shadow: none;
  height: 50px;
}
.table-card-row .search-input-group .search-input::placeholder {
  color: #0B9B7D;
}
.table-card-row .filter-dropdown,
.table-card-row .export-dropdown {
  height: 40px;
  min-width: 330px;
  max-width: 350px;
}
.table-card-row .filter-dropdown >>> .filter-dropdown-menu,
.table-card-row .export-dropdown >>> .export-dropdown-menu {
  min-width: 200px;
}
.table-card-row .export-dropdown >>> .export-dropdown-menu .inner-icon,
.table-card-row .filter-dropdown >>> .filter-dropdown-menu .inner-icon {
  width: 8px;
  height: 8px;
}
.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item.active,
.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item:active,
.table-card-row
  .export-dropdown
  >>> .export-dropdown-menu
  button.dropdown-item.active,
.table-card-row
  .export-dropdown
  >>> .export-dropdown-menu
  button.dropdown-item:active {
  background-color: var(--light);
}
.table-card-row .export-dropdown >>> .export-dropdown-button {
  padding: 6px 20px;
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .insight-cards-row {
    height: 350px;
  }
  .insight-cards-row .filter-dropdown {
    min-width: 35px;
  }
  .insight-cards-row .filter-dropdown >>> .add-customer-btn {
    height: 50px;
    padding-right: 100px;
  }
}
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .insight-cards-row {
    height: auto;
  }
  .insight-cards-row .filter-dropdown {
    height: 200px;
    min-width: auto;
  }
  .insight-cards-row .filter-dropdown >>> .add-customer-btn {
    /* height: 50px; */
    /* padding-left: 100px */
  }
  .table-card-row .search-input-group {
    max-width: none;
  }
}
</style>
